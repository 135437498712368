<template>
    <transition name="fade">
        <div v-if="mountMenu" class="mobile-dropdown-menu">
            <transition name="slide-from-bottom">
                <div v-if="mountWrapper" class="mobile-dropdown-menu__wrapper">
                    <DropdownOptions
                        v-if="siteDropdown"
                        :options="siteDropdown"
                        class="mobile-dropdown-menu__component"
                        @close="() => mount(false)"
                    >
                        <button
                            type="button"
                            class="mobile-dropdown-menu__close-button"
                            @click="() => mount(false)"
                        >
                            <img :src="$assets.gray.exit" alt="Cerrar" />
                        </button>
                    </DropdownOptions>
                </div>
            </transition>
            <MiscOverlay mode="dark" :handler="() => mount(false)" />
        </div>
    </transition>
</template>

<script lang="ts" setup>
import { useRootStore } from '~/store/root'

const { setSiteDropdown, siteDropdown } = useRootStore()

const mountMenu = ref(false)

const mountWrapper = ref(false)

watch(siteDropdown, (menu) => {
    !!menu && mount(true)
})

const Route = useRoute()

const RoutePath = computed(() => Route.fullPath)

watch(RoutePath, (menu) => {
    !!menu && mount(false)
})

const mount = (mount: boolean) => {
    if (mount) {
        mountMenu.value = mount

        setTimeout(() => (mountWrapper.value = mount), 100)
    } else {
        mountWrapper.value = mount

        setTimeout(() => {
            mountMenu.value = mount
        }, 100)

        setTimeout(() => setSiteDropdown(null), 210)
    }
}
</script>

<style lang="postcss" scoped>
.mobile-dropdown-menu {
    @apply fixed inset-0 z-[100];
    &__wrapper {
        @apply fixed bottom-0 left-0 right-0 z-10 overflow-hidden;
    }
    &__close-button {
        @apply absolute right-2 top-1.5 h-6 w-6;
        img {
            @apply h-full w-full;
        }
    }
    &__component {
        @apply w-full rounded-t-xl;
    }
}
</style>
